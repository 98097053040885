enum URL_ENUM {
  WHEN_WHERE_TO_GO = 'ou-et-quand-partir',
  WHEN_TO_GO = 'quand-partir',
  WHO_ARE_WE = 'qui-sommes-nous',
  CULTURE_AND_TRAVEL = 'culture-et-voyages',
  SPECIAL_OFFERS = 'offres-speciales',
  QUOTE = 'devis-sur-mesure',
  AROUND_TRAVEL = 'autour-du-voyage',
  CULTURAL_EVENTS = 'evenements-culturels',
  NEWS = 'actualites',
  TRAVEL_NOTEBOOK = 'carnet-de-voyage',
  CATALOGUES = 'catalogues',
  AUTOTOUR = 'autotour',
  GUIDED_TOUR = 'circuit-accompagne',
  ECOTOURISM = 'ecotourisme',
  CHRISTMAS = 'noel-et-reveillons',
  INSPIRATION_VOYAGE = 'inspirations-voyage'
}

export default URL_ENUM;
